.primary {
  @apply h-full aspect-1 text-white;

  & :global(.close) {
    @apply w-3 sm:w-4 fill-current stroke-current;
  }

  & :global(.burger) {
    @apply w-6 sm:w-8 fill-current stroke-current;
  }

  & :global(> .toggle) {
    @apply h-full w-full flex flex-col items-center justify-center text-white;
    @apply transition-colors duration-200 bg-black-100 border-0 rounded-none;
    @apply focus:outline-none hover:bg-black-100;
  }
}

.submenu {
  @apply fixed transition-transform duration-200 transform top-14 sm:top-20 right-0 z-50;
  @apply h-screen w-full bg-black-100 translate-x-full max-w-sm;

  &:global(.has-admin-bar) {
    @apply top-[104px] sm:top-[128px] md:top-[116px];
  }

  & :global(.primary > li) {
    @apply py-4 block w-full border-b border-white/15;

    & :global(> a) {
      @apply hover:text-good-green-50;
    }
  }

  & :global(.sub-menu) {
    @apply my-4 text-lg border-l-2 border-white;
    & > li a {
      @apply pl-4 mb-4 hover:text-good-green-50;
    }

    & li:last-of-type a {
      @apply mb-0;
    }
  }

  & :global(.sub-sub-menu) {
    @apply ml-4 mb-4 text-lg border-l-2 border-white;
    & > li a {
      @apply pl-4 mb-4 hover:text-good-green-50;
    }
  }
}

.on {
  @apply translate-x-0;
}

.social {
  @apply flex pt-4 pb-10;

  &:global(.dark li a) {
    @apply text-white bg-white/10 hover:bg-white/30;
  }

  & :global(li a) {
    @apply h-10 w-10 flex items-center justify-center rounded-full;
    @apply bg-black-100/15 hover:bg-black-100/30 mr-6;
  }

  & :global(.service) {
    @apply w-4 fill-current;
  }

  & :global(.youtube) {
    @apply w-[22px];
  }

  & :global(.twitter) {
    @apply w-7;
  }
}
