.siteLogo {
  @apply w-auto h-[33px];

  @screen sm {
    @apply h-[40px];
  }

  @screen lg {
    @apply h-[50px];
  }
}
