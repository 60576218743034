.header {
  @apply fixed inset-x-0 inset-y-auto z-100;
  @apply transition-colors duration-200 ease-linear;

  & :global(.menu-bar) {
    @apply flex items-center h-14 sm:h-20 relative;

    &::after {
      @apply block absolute h-px w-full bg-black-100/10 -bottom-px;
      @apply pointer-events-none content-[""];
    }
  }
}

.opaque {
  @apply bg-white text-black-100;
}

.textBlack {
  @apply text-black-100;
}

.logo {
  @apply flex items-center ml-3 sm:ml-4 md:ml-8 flex-grow justify-start;

  @apply lg:absolute lg:-left-8 lg:bg-white lg:h-full lg:z-10 lg:pl-8;
}

.overlay {
  @apply fixed top-0 left-0 z-40 w-full h-full bg-black-110/25 transition-opacity opacity-0;
  @apply pointer-events-none;
}

.is-active {
  @apply opacity-100;
}

@media print {
  .header {
    display: none;
  }
}
