.menu {
  @apply flex flex-wrap border-b border-white/20 mb-8;
  & > li {
    @apply w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 mb-4;
    & > a {
      @apply text-lg font-bold pb-4;
    }
  }
  & :global(a) {
    @apply block hover:underline pb-4;
  }

  &:global(.tertiary) {
    & a {
      @apply text-base font-normal;
    }
  }
}

.heading {
  @apply font-sans text-lg mb-6;
}

.secondary {
  @apply mt-6 pb-6 md:mt-0 md:pb-0 md:flex;

  & a {
    @apply hover:underline;
  }

  & li {
    @apply pb-4 md:pb-0;

    &:not(:last-child)::after {
      @apply content-["\2219"] px-2;
    }
  }
}
