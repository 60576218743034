.content {
  @apply flex font-sans mb-8 flex-col md:flex-row;

  & :global(h4) {
    @apply mb-4 text-lg font-sans font-semibold;
  }

  & :global(.section) {
    @apply w-full md:w-1/2;
  }

  & :global(.columns) {
    @apply flex flex-col lg:flex-row;
  }

  & :global(.column) {
    @apply w-full lg:w-1/2 mb-6 lg:mb-0;
  }
}

.social {
  @apply flex;

  &:global(.dark li a) {
    @apply text-white bg-white/10 hover:bg-white/30;
  }

  & :global(li a) {
    @apply h-13 w-13 flex items-center justify-center rounded-full;
    @apply bg-black-100/15 hover:bg-black-100/30 mr-5;
  }

  & :global(.service) {
    @apply w-5 fill-current;
  }

  & :global(.youtube) {
    @apply w-[26px];
  }

  & :global(.twitter) {
    @apply w-9;
  }
}
