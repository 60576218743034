/* Default style */
/* Do not add margins here, add placement to your specific button */
.button {
  @apply leading-none transition-colors duration-200 rounded border-2 border-transparent;
  @apply px-5 py-3 font-sans text-lg font-bold whitespace-nowrap;
  @apply disabled:cursor-not-allowed;
  @apply disabled:opacity-70 disabled:bg-black-25 disabled:border-black-25;
  @apply disabled:text-black-100;
  @apply disabled:hover:bg-black-25;
}

.arrow {
  @apply inline-block w-3 ml-2.5 -mt-[2px];
}

/*  FIXME t ex blurbs */
.size-small {
  @apply px-3 py-2 text-base font-bold;
}

.size-mini {
  @apply px-3 py-2 text-sm font-bold;
}

.text-sans {
  @apply font-sans;
}

/* Themes */
.theme-secondary {
  @apply bg-transparent border-black-100 border;
  @apply hover:bg-white/75;
  @apply focus:bg-white/80;
  @apply active:bg-white/80;
  @apply disabled:bg-transparent disabled:hover:bg-transparent disabled:hover:border-black-100/80;
}

.theme-good-green {
  @apply bg-good-green-100 border-good-green-100 text-white;
  @apply hover:bg-good-green-110 hover:border-good-green-110;
  @apply font-sans !important;
}

.theme-good-blue {
  @apply bg-good-blue-100 border-good-blue-100 text-white;
  @apply hover:bg-good-blue-110;
  @apply font-sans !important;
}

.theme-good-light-blue {
  @apply bg-good-blue-50 border-good-blue-50 text-white;
  @apply hover:bg-good-blue-80 hover:border-good-blue-80;
  @apply font-sans !important;
}

.theme-light-lime {
  @apply bg-summer-green-50 border-summer-green-50 text-black-100;
  @apply hover:bg-summer-green-50/50;
  @apply focus:bg-summer-green-75/80 focus:border-summer-green-75;
  @apply active:bg-summer-green-75;
  @apply disabled:hover:border-summer-green-75;
}

.theme-lime {
  @apply bg-summer-green-100 border-summer-green-100 text-black-100 hover:bg-summer-green-50;
  @apply hover:bg-summer-green-50;
  @apply focus:bg-summer-green-50/80;
  @apply active:bg-summer-green-50;
  @apply disabled:hover:border-summer-green-100;
}

.theme-green,
.theme-dark-green {
  @apply bg-green-100 border-green-100 text-black-100;
  @apply hover:bg-green-100/50;
  @apply disabled:hover:border-green-100;
}

.theme-light-green {
  @apply bg-green-50 border-green-50 text-black-100;
  @apply hover:bg-green-50/75 hover:border-green-100;
  @apply focus:bg-green-75/80 focus:border-green-100;
  @apply active:bg-green-75 active:border-green-100;
}

.theme-cyan {
  @apply bg-dark-cyan-100 border-dark-cyan-100 text-white;
  @apply hover:bg-dark-cyan-75;
  @apply focus:bg-green-100/80;
  @apply active:bg-dark-cyan-100;
}

.theme-text {
  @apply py-0 px-0 !important;
  @apply mx-0 border-none bg-transparent text-black-100 hover:underline;
  @apply active:bg-transparent;
  @apply focus:bg-transparent;
  @apply disabled:bg-transparent disabled:hover:bg-transparent;
}

.theme-light-cyan {
  @apply bg-dark-cyan-100/80 text-black-100 border-dark-cyan-100 hover:bg-dark-cyan-100;
}

.theme-black {
  @apply bg-black-100 border-black-100 text-white hover:bg-black-100/80;
}
