.menu {
  @apply w-full hidden lg:flex justify-end h-20;

  & :global(> li) {
    @apply hover:bg-good-blue-100/10 relative;
    &:hover::after {
      @apply bottom-0 left-0 absolute w-full bg-good-blue-100;
      @apply z-110 content-[""] h-[2px];
    }

    & > a {
      @apply box-content flex items-center h-full px-5 font-semibold whitespace-nowrap;
    }
  }

  &:global(.has-hover) {
    & :global(> li:hover) {
      & :global(> .sub-menu) {
        @apply block;
      }
    }
  }

  & :global(.caret) {
    @apply ml-2 w-[9px] h-[5px] transform rotate-0 transition-transform duration-200;

    &:global(.open) {
      @apply rotate-180;
    }
  }

  & :global(.sub-menu) {
    @apply text-black-100 absolute w-84 bg-white top-full;
    @apply z-10 pt-6 pb-2 px-8 font-normal;
    @apply hidden;

    &:global(.has-hover) {
      & :global(> li:hover) {
        & :global(.sub-sub-menu) {
          @apply block;
        }
      }
    }

    & a {
      @apply w-full block mb-4 hover:text-good-blue-100 relative;
    }

    & :global(.toggle) {
      @apply p-2 absolute right-0 top-0;
    }

    & :global(.caret) {
      @apply w-[9px] h-[5px] transform rotate-0 duration-200;

      &:global(.open) {
        @apply rotate-180;
      }
    }

    & :global(.sub-sub-menu) {
      @apply hidden pl-4;

      &:global(.is-open) {
        @apply block;
      }
    }
  }
}
