.template {
  @apply pt-8;

  & :global(div[data-block="core/paragraph"]) {
    @apply text-xl;
  }

  & :global(div[data-block="core/paragraph"] + div[data-block^="theme/"]) {
    @apply pt-6;
  }
}
